
import React from 'react';


import {Image,Alert} from 'react-bootstrap'
import mac from './img/logowebsite.png';
import Helmet from "react-helmet"

function Center() {
    return(
      
        
 <div style={{backgroundColor: "#C54245"}}  class="inner">
    <Helmet>
 
 <title>Akaya Megumi! - Desginer</title>
 
 
        
         </Helmet>

<div>
<Image src={mac} fluid />
</div>
<Alert variant="success">
      <Alert.Heading>Hey there! Welcome to my website!</Alert.Heading>
      <p>
      Ayaka! As a professional graphic designer, I've created an awesome website where I offer commission services for 2D and 3D Vtuber Models. My prices are super competitive, and I can bring your virtual avatar to life with stunning detail and creativity. Let me know if you're interested or have any questions!      </p>
      <hr/>
    </Alert>

{/* imgage yahan Ayegi  */}

</div>


    )
}

export default Center;