
import {Table,Nav,Col,Tab,Row} from 'react-bootstrap'
function Acenter() {


    return(

<div>

<Table striped bordered hover>
  <thead>
    <tr>
      <th>#</th>
      <th>Designer </th>
      <th>Email</th>
      <th>Desgins </th>
      <th>Work With</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1</td>
      <td>Akaya Megumi</td>
      <td>Sales@AkayaMegumi.ioslift.com</td>
      <td>VTuber Models</td>
      <td>2D, 3D + Rigging + Animation + Logo+ Etc </td>
    </tr>
   

    
  </tbody>
</Table>


<div style={{marginTop:80}}>

<center>

<h1>About-Us </h1>
</center>

</div>


<div>

<Tab.Container id="left-tabs-example" defaultActiveKey="first">
  <Row>
    <Col sm={3}>
      <Nav variant="pills" className="flex-column">
        <Nav.Item>
          <Nav.Link eventKey="first"><strong> About</strong></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="second"><strong> Our Goal</strong></Nav.Link>
        </Nav.Item>
      </Nav>
    </Col>
    <Col sm={9}>
      <Tab.Content>
        <Tab.Pane eventKey="first">
        <p> <a style={{fontSize:40}} >H</a>ey i'm Akaya Megumi A Graphic Desginer, in the ever-evolving realm of online content creation, a new and captivating phenomenon has taken center stage – Virtual YouTubers (VTubers). These digital personalities, often represented by animated characters, have revolutionized the way audiences engage with online content. Behind the mesmerizing and visually striking avatars lies a crucial figure in the VTuber world: the VTuber Graphics Designer.</p>
        </Tab.Pane>
        <Tab.Pane eventKey="second">
          <p> <a style={{fontSize:40}} >T</a>he VTuber Graphics Designer is the creative architect responsible for crafting the unique and eye-catching visual identities of these virtual personas. This skilled artist combines technical prowess with artistic flair to design the avatars, overlays, and animations that bring VTubers to life. From meticulously detailing facial expressions to creating dynamic backgrounds and effects, the VTuber Graphics Designer plays a pivotal role in enhancing the overall viewer experience.</p>
        </Tab.Pane>
      </Tab.Content>
    </Col>
  </Row>
</Tab.Container>



</div>
 
<div>

  
</div>
<div>

<p>  <center> <strong> Note :</strong> <a style={{color: 'red'}}>Hope You'll Love Our Desgining </a></center></p>

</div>

<div style={{marginBottom:30}}>

</div>

</div>

    )
    
}
export default Acenter