import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar'



import 'bootstrap/dist/css/bootstrap.min.css';
import { NavDropdown ,Nav,Form,FormControl ,Alert,Badge} from 'react-bootstrap';

function Header(){

    return(
    


 
    <div style={{paddingBottom:70}}>
   
  <Navbar fixed="top"  bg="light"  expand="lg">
  <Navbar.Brand style={{color: 'blue' , paddingRight: 10}} href="/" >
 
      Akaya Megumi
    </Navbar.Brand>

  <Navbar.Toggle aria-controls="basic-nav-dropdown" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="mr-auto ">
    <Nav.Link href="/about">About Me</Nav.Link>
   
    </Nav>
   
  </Navbar.Collapse>
</Navbar>
     
</div>




   

    )
    
    
    }
    
    export default Header;