
import './App.css';
import MainPage from './Silver'
import Header from './Header'
import Center from './Center'
import Footer from './Footer'
import Gold from './Gold'
import Paltinum from './Paltinum'

function HomePage() {


  return (
    <div className="App">
    
        <Header/>
        <Center/>
        <MainPage/>
        <Gold/>
        <Paltinum/>

         <Footer/>
    </div>
  );
}

export default HomePage;
