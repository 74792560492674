import Header from './Header'
import Acenter from './Acenter'
import Footer from './Footer'

import Helmet from "react-helmet"
function About() {


    return(
<>
<Header/>
<Helmet>
 
<title>About US </title>


       
        </Helmet>

        <div style={{backgroundColor: '#F8F8F8',textAlign:'center'}}>

        <h1 style={{fontSize:100}}> About US </h1>

        </div>

        <Acenter/>
        <Footer/>
        </>
    )
    
}
export default About