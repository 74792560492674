
import reactDom from 'react-dom';
import '.././node_modules/bootstrap/dist/css/bootstrap.min.css';
import React, {  } from 'react';
import {Button,Modal,Accordion,Card,Table ,Badge,Image,Row,Col,Container} from 'react-bootstrap'; 

import './Code.css'

import watchos from './img/silver.png';
import { useState } from 'react';
import {Helmet} from 'react-helmet'


function MainPage(){

return(
    <>
    <h2>Silver Package </h2>
   <div>
   <Image src={ watchos} width={300} height={300} />
   <div >
      <Table striped bordered hover>
      <thead>
        <tr>
        <th>#</th>
          <th>Platinum</th>
          <th>Min</th>
          <th>Max</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Half Body</td>
          <td>$550</td>
          <td>$800</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Half Body with Rigging</td>
          <td>$700</td>
          <td>$900</td>
        </tr>
        <tr>
        <td>3</td>
          <td>Full Body</td>
          <td>$650</td>
          <td>$1050</td>
        </tr>
        <tr>
        <td>4</td>
        <td>Full Body With Rigging</td>
          <td>$750</td>
          <td>$1100</td>
        </tr>
      </tbody>
    </Table>
</div>

   </div>
   
   
    <div style={{color:'blue'}}>



</div>
</>


)

}
export default MainPage;